@import 'bootstrap/scss/bootstrap';

@import '@heidelberg/hdmui-angular/scss/hdmui-variables.scss';
@import '@heidelberg/hdmui-angular/scss/hdmui-colors.scss';

$grid-gutter-width: 24px;
$spacer: 0.75rem;

button:focus {
    outline: none;
}

label {
    margin-bottom: 0;
}

.mat-mdc-text-field-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
}

.mat-mdc-form-field {
    width: 100%;
}

.mat-mdc-table .mat-mdc-row {
    user-select: inherit !important;
}

.mat-mdc-table td.mat-mdc-cell {
    user-select: inherit !important;
}

.content-no-header {
    min-height: calc(100vh - $hdmui-legal-line-height) !important;
}

.content-header {
    min-height: calc(100vh - $hdmui-legal-line-height - $hdmui-toolbar-height) !important;
}

.content-header-and-subnav {
    min-height: calc(100vh - $hdmui-legal-line-height - $hdmui-toolbar-height - $hdmui-tab-height) !important;
}

.loading-parent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mat-icon {
    box-sizing: content-box;
}

.mdc-list-item__primary-text,
.mdc-list-item__secondary-text {
    color: $text-primary !important;
}

#HdmuiCore .hdmui-empty-states__container {
    background: none !important;
}

#HdmuiCore .mat-mdc-form-field.vmi-mat-form-field-warnings .mat-mdc-input-element,
#HdmuiCore .mat-mdc-form-field.vmi-mat-form-field-warnings .mat-mdc-select,
#HdmuiCore .mat-mdc-form-field.vmi-mat-form-field-warnings .mat-date-range-input-container {
    color: $warning-strong;
}

#HdmuiCore .mat-mdc-form-field.vmi-mat-form-field-warnings .mdc-notched-outline__leading,
#HdmuiCore .mat-mdc-form-field.vmi-mat-form-field-warnings .mdc-notched-outline__notch,
#HdmuiCore .mat-mdc-form-field.vmi-mat-form-field-warnings .mdc-notched-outline__trailing {
    border-color: $warning-strong;
}

caption {
    display: none !important;
}

#HdmuiCore .hdmui-dialog .mat-toolbar h1,
#HdmuiCore .hdmui-dialog .mat-toolbar h2,
#HdmuiCore .hdmui-dialog .mat-toolbar h3,
#HdmuiCore .hdmui-dialog .mat-toolbar h4,
#HdmuiCore .hdmui-dialog .mat-toolbar h5,
#HdmuiCore .hdmui-dialog .mat-toolbar h6,
#HdmuiCore .hdmui-dialog .mat-toolbar p {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
